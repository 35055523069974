<template>
    <transition :name="routerTransition" mode="out-in">
        <router-view></router-view>
    </transition>
</template>

<script lang="ts">
import Vue from 'vue';
import themeConfig from '../../../themeConfig';
export default Vue.extend({

    data() {
        return {
            routerTransition  : themeConfig.routerTransition || 'none',
        }
    },

    created() {
        this.$vs.theme({ info: 'rgb(51, 60, 106)' });
        this.$vs.theme({ blue: 'rgb(57, 106, 255)' });
        this.$vs.theme({ warning: 'rgb(247, 191, 81)' });
        this.$vs.theme({ greenLight: 'rgb(34, 216, 115)' });
    }
})
</script>